import * as React from 'react';
import ReactDOM from "react-dom";
import 'bootstrap/dist/css/bootstrap.min.css';
import * as serviceWorker from "./serviceWorker";
import { BrowserRouter } from "react-router-dom";

// ** Spinner (Splash Screen)
import Spinner from './components/spinner/Fallback-spinner';
import axios from "axios";
import { env } from './env'
import { errorLogger } from './helper/utils';

// ** Lazy load app
const LazyApp = React.lazy(() => import('./App'));

const getSiteRoutes = async () => {
  try {
    const response = await axios({
      method: 'get',
      url: `${env.REACT_APP_STRAPIURL}/api/site-menus?populate=%2A&filters[site][id][$eq]=${env.REACT_APP_SITEID}`,
      headers: { 'Content-Type': 'application/json' }
    })
    if (response.status === 200 && response.data.data && response.data.data.length > 0 && response.data.data[0].hasOwnProperty('attributes')) {
      return response.data.data.sort((a, b) => a.attributes.menu_order - b.attributes.menu_order) ?? []
    } else {
      return []
    }
  }
  catch (error) {
    errorLogger('index.js', 'getRoutes()', error)
    return []
  }
}

const getSiteDetails = async () => {
  try {
    const response = await axios({
      method: 'get',
      url: `${env.REACT_APP_STRAPIURL}/api/site-settings?filters[site][id][$eq]=${env.REACT_APP_SITEID}&populate=%2A`,
      headers: { 'Content-Type': 'application/json' }
    })
    if (response.status === 200 && response.data.data && response.data.data.length) {
      const data = response.data.data[0];
      const color_settings = data.attributes.color_settings ?? {};
      setColorSettings(color_settings, data.attributes.header_icon ?? null);
      if (data.hasOwnProperty('attributes')) {
        document.getElementById("favicon").href = data.attributes.favicon ?? undefined;
        document.title = data.attributes.title ?? undefined;
      }
      return response.data.data[0] ?? {}
    } else {
      throw response.data
    }
  }
  catch (error) {
    errorLogger('index.js', 'getSiteDetails()', error)
  }
}

const setColorSettings = async (color_settings, headerIcon) => {
  let rgb = { r: '200', g: '12', b: '53' };
  if (color_settings.primary !== null && color_settings.primary !== undefined && color_settings.primary !== "") {
    let clr = color_settings.primary;
    rgb = clr.substring(4, clr.length - 1)
      .replace(/ /g, '')
      .split(',');
    if (rgb && rgb.length === 3) {
      rgb = { r: rgb[0], g: rgb[1], b: rgb[2] }
    }
  }

  window.localStorage.setItem("themeData", JSON.stringify({
    "primaryColor": color_settings.primary ? color_settings.primary : '#183B6B',
    "primaryRGB": rgb,
    "secondaryColor": color_settings.secondary ? color_settings.secondary : '#08A0D9',
    "tertiaryColor": color_settings.tertiary ? color_settings.tertiary : '#FEDF38',
    "footerbg": color_settings.footer_bg ? color_settings.footer_bg : '#06162F',
    "body_bg": color_settings.body_bg ? color_settings.body_bg : '#FFFFFF',
    "primaryText": color_settings.primary_text ? color_settings.primary_text : '#FFFFFF',
    "secondaryText": color_settings.secondary_text ? color_settings.secondary_text : '#4D4D4D',
    "headingText": color_settings.heading_colour ? color_settings.heading_colour : '#183B6B',
    "highlightText": color_settings.highlight_text ? color_settings.highlight_text : '#08A0D9',
    "headerIcon": headerIcon
  }));
}

const AppWithData = () => {
  const [loading, setLoading] = React.useState(true);
  const [siteData, setSiteData] = React.useState(null);

  React.useEffect(() => {
    const loadSiteData = async () => {
      const [siteDetails, siteRoutes] = await Promise.all([
        getSiteDetails(),
        getSiteRoutes()
      ])
      setSiteData({
        siteDetails, siteRoutes
      })
      setLoading(false);
    };
    loadSiteData();
  }, []);

  if (loading) {
    return <Spinner />;
  }

  return (
    <React.Suspense fallback={<Spinner />}>
      <LazyApp siteData={siteData} />
    </React.Suspense>
  );
};

// Render only after data is ready
ReactDOM.render(
  <BrowserRouter>
    <AppWithData />
  </BrowserRouter>,
  document.getElementById("root")
);

serviceWorker.unregister();